import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.show,"persistent":"","width":"720"}},[_c(VCard,{staticClass:"card-class",class:{ 'justify-center': _vm.$vuetify.breakpoint.smAndDown },attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"full-width d-flex accent-text card-title-width justify-space-between font-size-card-title line-height-InsText"},[_c('span',{staticClass:"font-weight-bold purple-dark--text"},[_vm._v(_vm._s(_vm.$t('petition.buildingContentSecurityTitle')))]),_c(VBtn,{attrs:{"text":"","fab":"","right":"","absolute":"","small":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"mt-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('petition.cardSubheader')))]),_vm._l((_vm.sortedServices),function(service,idx){return _c('ul',{key:idx},[_c('li',[_vm._v(_vm._s(_vm.$t(("coverage." + (service.name)))))])])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }