<template>
  <v-dialog
    :value="value"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    class="pa-0"
    width="956"
    height="750"
  >
    <v-card
      color="grey-light"
      class="card-class"
      flat
      :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
    >
      <v-btn
        text
        fab
        small
        absolute
        right
        @click="$emit('input')"
        class="mt-2"
        style="background-color: #E6ECF1;"
      >
        <v-icon color="#72777E">mdi-close</v-icon></v-btn
      >
      <v-card-title class="mt-2 mb-5">
        <div
          class="mx-auto purple-dark--text font-weight-black pt-5 pb-0"
          :class="$vuetify.breakpoint.smAndDown ? ' text-h5' : 'text-h4'"
        >
          {{ $t('petition.editPackageTitle') }}
        </div>
      </v-card-title>
      <v-card-text class="px-15">
        <v-row class="justify-center">
          <v-col
            cols="12"
            sm="6"
            md="5"
            class="text-center pb-0"
            v-for="item in sortedPetitions"
            :key="item.code"
          >
            <CurrentPackageChip :show-chip="petition.code === item.code" />
            <PackageCard
              :current-petition-code="item.code"
              :petition-title="$t(`petition.${item.title}`)"
              :petition-details="$t(`petition.${item.details}`)"
              :image="item.image"
              @selected-package="selectedService"
              :show-service-btn="item.showServiceButton"
              :show-service-dialog="item.dialog"
              :services-order="item.servicesOrder"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import PackageCard from '@/components/common/cards/PackageCard.vue';
import CurrentPackageChip from '@/components/common/chips/CurrentPackageChip.vue';
import petitionsMap from '@/config/petitionsMap.json';

export default {
  name: 'PackagesDialog',
  components: {
    CurrentPackageChip,
    PackageCard,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  methods: {
    selectedService(petitionCode, petitionTitle) {
      this.$emit('select-service', petitionCode, petitionTitle);
    },
  },
  computed: {
    ...mapGetters(['petition', 'step']),
    sortedPetitions() {
      return petitionsMap.petitions.sort((a, b) => a.order - b.order);
    },
  },
};
</script>
