<template>
  <v-card
    elevation="0"
    min-height="330"
    outlined
    rounded="xl"
    class="text-center border-top-card px-10 align-center justify-center d-flex flex-column mx-auto"
  >
    <img v-if="image" :src="image" />
    <h4 class="font-size-btn my-5 purple-dark--text">
      {{ petitionTitle }}
    </h4>
    <p v-if="petitionDetails" class="purple-dim--text">
      {{ petitionDetails }}
    </p>
    <v-btn
      v-if="showServiceBtn"
      @click="showDialog"
      text
      class="see-all-service text-none mb-4 primary--text font-weight-bold font-weight-bold"
    >
      <img class="mr-2" max-width="30" src="/images/share.png" />
      {{ $t('petition.seeAllCoverage') }}</v-btn
    >
    <v-btn
      v-if="petition.code !== currentPetitionCode"
      color="primary"
      block
      outlined
      rounded
      class="d-block font-weight-bold text-capitalize"
      style="border-width: 2px; flex: unset;"
      @click="$emit('selected-package', currentPetitionCode, petitionTitle)"
    >
      {{ $t('petition.choosePackage') }}
    </v-btn>
    <!-- Packages services dialogs -->
    <component :is="component" :show="dialog" @close-dialog="closeDialog" :services-order="servicesOrder" ></component>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import BuildingInsuranceServicesDialog from '@/components/common/dialogs/BuildingInsuranceServicesDialog.vue';
import ContentsInsuranceServicesDialog from '@/components/common/dialogs/ContentsInsuranceServicesDialog.vue';
import BuildingContentSecurityServicesDialog from '@/components/common/dialogs/BuildingContentSecurityServicesDialog.vue';

export default {
  name: 'PackageCard',
  components: {
    BuildingInsuranceServicesDialog,
    ContentsInsuranceServicesDialog,
    BuildingContentSecurityServicesDialog,
  },
  props: {
    currentPetitionCode: {
      type: Number,
      default: null,
      required: true,
    },
    petitionTitle: {
      type: String,
      default: '',
      required: true,
    },
    petitionDetails: {
      type: String,
      default: '',
      required: false,
    },
    image: {
      type: String,
      default: '',
      required: false,
    },
    showServiceBtn: {
      type: Boolean,
      default: false,
      required: false,
    },
    showServiceDialog: {
      type: String,
      default: '',
      required: false,
    },
    servicesOrder: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: () => ({
    component: null,
    dialog: false,
  }),
  computed: {
    ...mapGetters(['petition']),
  },
  methods: {
    showDialog() {
      this.component = this.showServiceDialog;
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
    }
  },
};
</script>
