<template>
  <v-row class="stepper-container top ma-0 mt-n3"  :class="$vuetify.breakpoint.smAndDown ? 'px-1': 'px-5'" justify="center">
    <v-col cols="12" sm="6" class="d-flex justify-center justify-sm-start" :class="$vuetify.breakpoint.smAndDown ? 'px-1': 'px-5'">
      <div class="d-flex align-md-center mr-5">
        <a :href="prospertyUrl" target="_blank">
          <img
            class="header-logo"
            max-width="300"
            src="/logos/prosperty.png"
          />
        </a>
      </div>
      <div class="d-flex align-md-center">
        <a :href="anytimeUrl" target="_blank">
          <img
            max-width="120"
            class="header-logo"
            contain
            src="/logos/anytime.png"
          />
        </a>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="d-flex justify-center justify-md-end align-center mx-auto purple-dark--text"
    >
      {{ $t('petition.selectedPackage') }}:
      <span class="font-weight-bold purple-dark--text ml-2" v-if="petition.title">{{
        petition.title
      }}</span>
      <v-btn
        @click.stop="$emit('showPackages', true)"
        text
        color="primary"
        class="ms-2 text-capitalize font-weight-bold"
        v-if="step === 1"
      >
        <img src="/insuranceIcons/edit.svg" />
        <span class="ml-2">{{ $t('petition.editBtn') }}</span></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { PROSPERTY_URL, ANYTIME_URL } from '@/config/app';

export default {
  name: 'StepperMainHeader',
  data: () => ({
    prospertyUrl: PROSPERTY_URL,
    anytimeUrl: ANYTIME_URL,
  }),
  computed: {
    ...mapGetters(['petition', 'step']),
  },
};
</script>
