<template>
  <v-chip
    class="mx-auto d-block mb-2 purple-main--text font-weight-black visibly-hidden"
    color="purple-light primary--text"
    :class="{ visible: showChip }"
    >{{ $t('currentPackage').toUpperCase() }}</v-chip
  >
</template>

<script>
export default {
  name: 'CurrentPackageChip',
  props: {
    showChip: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>
