<template>
  <v-row no-gutters class="ml-4 px-4 stepper-row-mobile pb-8">
    <v-col class="stepper-container-mobile" cols="12">
      <v-stepper-step
        :complete="step > 1"
        :step="step"
        class="pb-2"
        v-if="step === 1"
      >
        <v-row>
          <v-col>
            <span class="mb-2 mr-1">{{ $t('stepper.step') }} 1:</span>
            <span class="step-label font-weight-bold">{{
              $t('stepper.propertyDetails')
            }}</span>
          </v-col>
        </v-row>
      </v-stepper-step>
      <v-stepper-step
        :complete="step > 2"
        :step="step"
        class="pb-2"
        v-if="step === 2"
      >
        <v-row>
          <v-col>
            <span class="mb-2 mr-1">{{ $t('stepper.step') }} 2:</span>
            <span class="step-label font-weight-bold">
              {{
                petition.rent == 1
                  ? $t('stepper.ownerDetails')
                  : $t('stepper.giveUsDetailsAboutYou')
              }}</span
            >
          </v-col>
        </v-row>
      </v-stepper-step>
      <v-stepper-step
        :complete="step > 3"
        :step="step"
        class="pb-2"
        v-if="step == 3"
      >
        <v-row>
          <v-col>
            <span class="mb-2 mr-1">{{ $t('stepper.step') }} 3:</span>
            <span class="step-label">{{ $t('stepper.offerDetails') }}</span>
          </v-col>
        </v-row>
      </v-stepper-step>
      <v-stepper-step
        :complete="step > 3"
        :step="step"
        class="pb-2"
        v-if="step === 4"
      >
        <v-row>
          <v-col>
            <span class="mb-2 mr-1">{{ $t('stepper.step') }} 4:</span>
            <span class="step-label">{{
              $t('stepper.requestSubmission')
            }}</span>
          </v-col>
        </v-row>
      </v-stepper-step>
      <v-row no-gutters>
        <v-col cols="3" class="px-1">
          <v-progress-linear
            :value="step > 1 ? 100 : 50"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
        </v-col>
        <v-col cols="3" class="px-1">
          <v-progress-linear
            v-if="step === 2"
            value="50"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
          <v-progress-linear
            v-else
            :value="step > 2 ? 100 : 0"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
        </v-col>
        <v-col cols="3" class="px-1">
          <v-progress-linear
            v-if="step === 3"
            value="50"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
          <v-progress-linear
            v-else
            :value="step > 3 ? 100 : 0"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
        </v-col>
        <v-col cols="3" class="px-1">
          <v-progress-linear
            :value="step === 4 ? 100 : 0"
            color="primary"
            class="mt-3"
            height="6"
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StepperStepsMobile',
  computed: {
    ...mapGetters(['petition', 'step']),
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__step .v-stepper__label {
  span {
    color: #6c6fb7;
  }
  span:nth-child(2) {
    font-size: 18px;
  }
}
.v-stepper__step--active .v-stepper__label {
  span:nth-child(1) {
    color: #613cf8;
  }
  span:nth-child(2) {
    color: #302479;
    font-size: 18px;
  }
}
</style>
