<template>
  <v-dialog :value="show" persistent width="720">
    <v-card
      class="card-class"
      flat
      :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
    >
      <v-card-title
        class="
            full-width
            d-flex
            accent-text
            card-title-width
            justify-space-between
            font-size-card-title
            line-height-InsText
          "
      >
        <span class="font-weight-bold purple-dark--text">{{ $t('petition.buildingContentSecurityTitle') }}</span>
        <v-btn text fab right absolute small @click="$emit('close-dialog')">
          <v-icon color="grey">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <p>{{ $t('petition.cardSubheader') }}</p>
        <ul v-for="(service, idx) in sortedServices" :key="idx">
          <li>{{ $t(`coverage.${service.name}`) }}</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import petitionsMap from '@/config/petitionsMap.json';

export default {
  name: 'BuildingContentSecurityServicesDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
    servicesOrder: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    sortedServices() {
      return this.servicesOrder.map(i =>
        petitionsMap.services.find(el => el.order === i),
      );
    },
  },
};
</script>
