import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"pa-0",attrs:{"value":_vm.value,"persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":"956","height":"750"}},[_c(VCard,{staticClass:"card-class",class:{ 'justify-center': _vm.$vuetify.breakpoint.smAndDown },attrs:{"color":"grey-light","flat":""}},[_c(VBtn,{staticClass:"mt-2",staticStyle:{"background-color":"#E6ECF1"},attrs:{"text":"","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){return _vm.$emit('input')}}},[_c(VIcon,{attrs:{"color":"#72777E"}},[_vm._v("mdi-close")])],1),_c(VCardTitle,{staticClass:"mt-2 mb-5"},[_c('div',{staticClass:"mx-auto purple-dark--text font-weight-black pt-5 pb-0",class:_vm.$vuetify.breakpoint.smAndDown ? ' text-h5' : 'text-h4'},[_vm._v(" "+_vm._s(_vm.$t('petition.editPackageTitle'))+" ")])]),_c(VCardText,{staticClass:"px-15"},[_c(VRow,{staticClass:"justify-center"},_vm._l((_vm.sortedPetitions),function(item){return _c(VCol,{key:item.code,staticClass:"text-center pb-0",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('CurrentPackageChip',{attrs:{"show-chip":_vm.petition.code === item.code}}),_c('PackageCard',{attrs:{"current-petition-code":item.code,"petition-title":_vm.$t(("petition." + (item.title))),"petition-details":_vm.$t(("petition." + (item.details))),"image":item.image,"show-service-btn":item.showServiceButton,"show-service-dialog":item.dialog,"services-order":item.servicesOrder},on:{"selected-package":_vm.selectedService}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }