<template>
  <v-stepper
    alt-labels
    class="pt-6 pa-0 elevation-0 full-width mr-n4"
    v-model="step"
  >
    <!-- Stepper Header -->
    <StepperMainHeader @showPackages="val => showPackagesDialog = val" />

    <!-- Packages Dialog -->
    <PackagesDialog @select-service="selectService" v-model="showPackagesDialog"/>

    <!-- Stepper desktop -->
    <StepperStepsDesktop />

     <!-- Stepper mobile -->
    <StepperStepsMobile />

    <v-stepper-items>
      <slot />
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import StepperMainHeader from '@/components/StepperMainHeader';
import PackagesDialog from '@/components/common/dialogs/PackagesDialog.vue';
import StepperStepsDesktop from '@/components/StepperStepsDesktop.vue';
import StepperStepsMobile from '@/components/StepperStepsMobile.vue';

export default {
  name: 'StepperLayout',
  components: { StepperMainHeader, StepperStepsDesktop, StepperStepsMobile, PackagesDialog },
  data: () => ({
    showPackagesDialog: false,
  }),
  computed: {
    ...mapGetters(['loading', 'petition', 'step']),
  },
  methods: {
    ...mapMutations(['setPetition', 'setPriceProtection', 'setLoading']),

    selectService(code, title){
      this.petition.code = code;
      this.petition.title = title;
      this.setPetition(this.petition);
      this.showPackagesDialog = false;

      this.$axios
        .get('petition')
        .then(({ data }) => (this.petitionServices = data));

      if (this.petition.code == 4682) {
        this.$axios
          .post(`get-quotation-service`, { code: 3030 })
          .then(({ data }) => {
            this.setPriceProtection(data);
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err);
            this.setLoading(false);
          });
      }
    this.checkAmount();
    },
    checkAmount() {
      if (this.petition.code == 212) {
        this.petition.resort.ownerNumber = 1;
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.petition.resort.ownerNumber > 1) {
            return (this.petition.resort.owners[0].amount = 0);
          }
          if (this.petition.resort.ownerNumber == 1) {
            return (this.petition.resort.owners[0].amount = 100);
          }
        });
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.row .stepper-container.top {
  border-bottom: 1px solid #E6ECF1;
}
.v-stepper__items {
  background-color: #F7F9FA;
}
@media only screen and (min-width: 959px) {
  .step-label {
    min-height: 38px;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 959px) {
  .stepper-row-desktop {
    display: none;
  }
}
@media only screen and (min-width: 959px) {
  .stepper-row-mobile {
    display: none;
  }
}
.stepper-container-mobile {
  .v-stepper__label {
    display: flex !important;
  }
}
</style>
